import { useCallback, useEffect } from 'react';

const useSocket = ({ socket, channels = ['public'], onCommand = null }) => {
    const onMessage = useCallback(
        (socketMessage) => {
            const { command = null, args = null } = socketMessage || {};
            if (onCommand !== null) {
                console.log('new message!', socketMessage, onCommand);
                onCommand(command, args);
            }
        },
        [onCommand],
    );

    const postCommand = useCallback(
        (command, args = []) => {
            channels.forEach((chan) => {
                console.log('socket', socket);
                console.log('command!', command, args);
                socket.emit('message', { command, args, channel: `${chan}:input` });
            });
        },
        [socket, channels],
    );

    useEffect(() => {
        if (socket !== null) {
            socket.on('message', onMessage);
        }
        return () => {
            if (socket !== null) {
                socket.off('message', onMessage);
            }
        };
    }, [socket, channels, onMessage]);

    return {
        postCommand,
    };
};

export default useSocket;
