import Base from './Base';

class SessionApi extends Base {
    constructor(opts = {}) {
        super({
            ...opts,
            routes: {
                state: 'app/state',
                show: 'session/:id',
                current: 'session/current',
                ...(opts.routes || null),
            },
        });
    }

    find(id) {
        return this.requestGet(
            this.route(this.route('show'), {
                id,
            }),
        );
    }

    current() {
        return this.requestGet(this.route('current'));
    }

    state() {
        return this.requestGet(this.route('state'));
    }
}

export default SessionApi;
