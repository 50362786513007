import Base from './Base';

class CuesApi extends Base {
    constructor(opts = {}) {
        super({
            ...opts,
            routes: {
                index: 'cues',
                show: 'cues/:id',
                define: 'cues/define',
                current: 'cues/current',
                showCue: 'cues/:id/show',
                hideCue: 'cues/:id/hide',
                interactions: 'cues/:id/interactions',
                uncue: 'uncue',
                ...(opts.routes || null),
            },
        });
    }

    find(id) {
        return this.requestGet(
            this.route(this.route('show'), {
                id,
            }),
        );
    }

    get(query = {}, page = null, count = null) {
        const finalQuery = {
            ...query,
        };
        if (page !== null) {
            finalQuery.page = page;
        }
        if (count !== null) {
            finalQuery.count = count;
        }
        return this.requestGet(this.route('index'), finalQuery);
    }

    current() {
        return this.requestGet(this.route('current'));
    }

    create(cue) {
        return this.requestPost(this.route('define'), [cue]);
    }

    define(cueArray) {
        return this.requestPost(this.route('define'), cueArray);
    }

    showCue(id) {
        return this.requestPost(this.route('showCue'), { id });
    }

    hideCue(id) {
        return this.requestPost(this.route('hideCue'), { id });
    }

    uncue() {
        return this.requestPost(this.route('uncue'));
    }

    interactions(id) {
        return this.requestGet(this.route('interactions'), { id });
    }

    interact(id, data) {
        return this.requestPost(this.route('interactions', { id }), data);
    }
}

export default CuesApi;
