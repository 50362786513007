import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';

import useSocket from '../hooks/useSocket';

import { useApi } from './ApiContext';
import { useSocket as useSocketContext } from './SocketContext';

const CueContext = React.createContext(null);

export const useCueContext = () => useContext(CueContext);

export const useCue = () => {
    const { cue = null } = useCueContext();
    return cue;
};

export const useCues = () => {
    const { cues = null } = useCueContext();
    return cues;
};

export const useInteractions = () => {
    const { interactions = null } = useCueContext();
    return interactions;
};

export const useSetCue = () => {
    const { setCue = () => {}, postCommand = () => {} } = useCueContext();
    return useCallback(
        (id = null, data = null) => {
            if (id === null) {
                postCommand('uncue');
            } else {
                console.log('cue', [id, data]);
                postCommand('cue', [id, data]);
            }
        },
        [setCue, postCommand],
    );
};

export const useDefineCues = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(
        (cueArray) => {
            postCommand('define', cueArray);
        },
        [postCommand],
    );
};

export const useStart = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(() => {
        postCommand('start');
    }, [postCommand]);
};

export const useStop = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(() => {
        postCommand('stop');
    }, [postCommand]);
};

export const useUncue = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(() => {
        postCommand('uncue');
    }, [postCommand]);
};

export const useEnd = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(() => {
        postCommand('end');
    }, [postCommand]);
};

export const useReset = () => {
    const { postCommand = null } = useCueContext();
    return useCallback(() => {
        postCommand('reset');
    }, [postCommand]);
};

const propTypes = {
    cue: PropTypes.shape({
        id: PropTypes.string,
    }),
    interactions: PropTypes.array, // eslint-disable-line
    cues: PropTypes.array, // eslint-disable-line
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    cue: null,
    interactions: [],
    cues: [],
};

export const CueProvider = ({
    cue: initialCue,
    interactions: initialInteractions,
    cues: initialCues,
    children,
}) => {
    const api = useApi();
    const { input, output } = useSocketContext();

    const [cue, setCue] = useState(initialCue);
    const [interactions, setInteractions] = useState(initialInteractions);
    const [sessionState, setSessionState] = useState(null);
    const [cues, setCues] = useState(initialCues);

    const onCommand = useCallback(
        (command, data) => {
            const [cueOrArgs = null, extraData = null] = data || [];
            console.log(cueOrArgs);
            const {
                currentState = null,
                // data: cueData = {},
                // sessionId: cueSessionId = null,
            } = cueOrArgs || {};

            const currentCue = isString(cueOrArgs) ? cues.find(({ id }) => id === cueOrArgs) : cueOrArgs;

            console.log('onCommand bro', command, data, cueOrArgs, extraData); // eslint-disable-line

            switch (command) {
                case 'cue':
                    setCue({ ...currentCue, extraData });
                    break;
                case 'cues':
                    setCues([...cueOrArgs]);
                    break;
                case 'uncue':
                    setCue(null);
                    break;
                case 'state':
                    setSessionState(currentState);
                    break;
                case 'reset':
                    setCue(null);
                    setInteractions([]);
                    break;
                case 'start':
                    // setCue(null);
                    // setInteractions([]);
                    break;
                case 'end':
                    setCue(null);
                    setInteractions([]);
                    break;
                case 'interaction':
                    // console.log('interaction');
                    // setInteractions((prevState) => [
                    //     ...prevState.filter(
                    //         ({ id = null }) => cueOrArgs !== null && id !== cueOrArgs.id,
                    //     ),
                    //     cueOrArgs,
                    // ]);
                    break;
                case 'uninteractions':
                    // setInteractions((current) =>
                    //     current.filter(({ id }) => (cueOrArgs || []).indexOf(id) === -1),
                    // );
                    break;
                default:
                    break;
            }
        },
        [setCue, setInteractions, setSessionState],
    );

    // Socket output to server input
    const { postCommand } = useSocket({
        socket: input,
    });

    // Listen to the server output for updates
    useSocket({
        socket: output,
        onCommand,
    });

    // Initial state from server
    useEffect(() => {
        api.cues.get().then((items) => {
            setCues(items);
        }).catch(() => {});
    }, [api, setCues]);

    useEffect(() => {
        api.cues.current().then((item) => {
            setCue(item);
        }).catch(() => {});
    }, [api, setCue]);

    const value = useMemo(
        () => ({ cue, setCue, sessionState, interactions, cues, postCommand }),
        [cue, setCue, sessionState, interactions, cues, postCommand],
    );

    return <CueContext.Provider value={value}>{children}</CueContext.Provider>;
};

CueProvider.propTypes = propTypes;
CueProvider.defaultProps = defaultProps;

export default CueContext;
