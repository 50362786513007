import { useRef, useMemo } from 'react';
import io from 'socket.io-client';

const useSocketIO = ({ host, namespace = null, path = null }) => {
    const refSocket = useRef(null);
    const socket = useMemo(() => {
        if (refSocket.current !== null) {
            refSocket.current.destroy();
        }
        const ioHost = namespace !== null ? `${host}/${namespace}` : host;
        const newSocket = io(ioHost, path !== null ? { path } : null);
        newSocket.on('connect', () => {
            console.log('socket id connected!', ioHost, namespace, path, newSocket.id);
            // newSocket.emit('message', { command: 'cue', args: ['chat'] }); // test cue
            refSocket.current = newSocket;
        });
        return newSocket;
    }, [refSocket, host, namespace]);
    return socket;
};

export default useSocketIO;
