/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useMemo, useContext } from 'react';

import useSocketIO from '../hooks/useSocketIO';

const SocketContext = React.createContext(null);

export const useSocket = () => useContext(SocketContext);

const propTypes = {
    children: PropTypes.node.isRequired,
    host: PropTypes.string.isRequired,
};

const defaultProps = {

};

export const SocketProvider = ({ host, children }) => {
    const input = useSocketIO({ host, path: '/socket-input' });
    const output = useSocketIO({ host, path: '/socket-output' });
    const value = useMemo(
        () => ({
            input,
            output,
        }),
        [input, output],
    );
    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = propTypes;
SocketProvider.defaultProps = defaultProps;

export default SocketContext;
