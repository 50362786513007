import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import { parse as parseQueryString } from 'query-string';
import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { ApiProvider } from '../contexts/ApiContext';
import { CueProvider } from '../contexts/CueContext';
import { NetworkProvider } from '../contexts/NetworkContext';
import { SocketProvider } from '../contexts/SocketContext';

const Admin = React.lazy(() => import('./Admin'));
const App = React.lazy(() => import('./App'));

const { site: siteQueryString = window.location.search.indexOf('admin') !== -1 ? 'admin' : null } =
    parseQueryString(window.location.search);

const propTypes = {
    site: PropTypes.string,
    locale: PropTypes.string,
    translations: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    socket: PropTypes.string,
    api: PropTypes.string,
};

const defaultProps = {
    site: siteQueryString !== null ? siteQueryString.toLowerCase() : null,
    locale: 'fr',
    translations: {},
    routes: {},
    socket:
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:5100'
            : 'https://passedate.cuecue.io',
    api:
        process.env.NODE_ENV !== 'production'
            ? 'http://localhost:5100/api/'
            : 'https://passedate.cuecue.io/api/',
};

function Container({ site, locale, translations, routes, socket, api }) {
    return (
        <IntlProvider locale={locale} messages={translations[locale] || translations}>
            <BrowserRouter>
                <RoutesProvider routes={routes}>
                    <NetworkProvider>
                        <ApiProvider baseUrl={api}>
                            <SocketProvider host={socket}>
                                <CueProvider>
                                    <Suspense fallback={<div />}>
                                        {site === 'admin' ? <Admin /> : <App />}
                                    </Suspense>
                                </CueProvider>
                            </SocketProvider>
                        </ApiProvider>
                    </NetworkProvider>
                </RoutesProvider>
            </BrowserRouter>
        </IntlProvider>
    );
}

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
