/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import Api from '../lib/api/Api';

const ApiContext = React.createContext(null);

export const useApiContext = () => useContext(ApiContext);

export const useApi = () => {
    const { api = null } = useApiContext() || {};
    return api;
};

export const useApiBaseUrl = () => {
    const { baseUrl = null } = useApiContext() || {};
    return baseUrl;
};

const propTypes = {
    api: PropTypes.instanceOf(Api),
    baseUrl: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    api: null,
    baseUrl: undefined,
};

export const ApiProvider = ({ api: initialApi, baseUrl, children }) => {
    const previousApi = useApi();
    const api = useMemo(
        () =>
            initialApi ||
            previousApi ||
            new Api({
                baseUrl,
            }),
        [previousApi, initialApi, baseUrl],
    );

    const value = useMemo(() => ({ api, baseUrl }), [api, baseUrl]);
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = propTypes;
ApiProvider.defaultProps = defaultProps;

export default ApiContext;
