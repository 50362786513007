import Base from './Base';
import CuesApi from './Cues';
import InteractionsApi from './Interactions';
import SessionsApi from './Sessions';

class Api extends Base {
    constructor(opts = {}) {
        super(opts);
        this.cues = new CuesApi(opts);
        this.sessions = new SessionsApi(opts);
        this.interactions = new InteractionsApi(opts);
    }
}

export default Api;
