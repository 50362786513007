/* eslint-disable react/jsx-props-no-spreading */
import { useWindowEvent } from '@folklore/hooks';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState, useCallback } from 'react';

function getOnline() {
    return typeof window !== 'undefined' && window.navigator !== 'undefined'
        ? window.navigator.onLine || false
        : false;
}

const NetworkContext = React.createContext({
    online: getOnline(),
});

export function useNetworkContext() {
    return useContext(NetworkContext);
}

export function useOnline() {
    const { online } = useNetworkContext();
    return online;
}

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {};

export function NetworkProvider({ children }) {
    const [online, setOnline] = useState(getOnline());
    const onOnline = useCallback(() => {
        setOnline(true);
    }, [setOnline]);
    const onOffline = useCallback(() => {
        setOnline(false);
    }, [setOnline]);
    useWindowEvent('online', onOnline);
    useWindowEvent('offline', onOffline);
    const value = useMemo(
        () => ({
            online,
        }),
        [online],
    );
    return <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>;
}

NetworkProvider.propTypes = propTypes;
NetworkProvider.defaultProps = defaultProps;

export default NetworkContext;
