import React from 'react';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';

import Container from './components/Container';
import shouldPolyfill from './polyfills/should';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function getAppProps() {
    return window.props || {};
}

function renderApp(props) {
    const element = document.getElementById('app');
    const container = React.createElement(Container, props);
    const strictMode = React.createElement(React.StrictMode, {}, container);
    const root = createRoot(element);
    root.render(strictMode);
}

const props = getAppProps();
const { site = null, locale = 'fr' } = props;

if (shouldPolyfill()) {
    import('./polyfills/index')
        .then(() => import(`./polyfills/intl-${locale}`))
        .then(() => renderApp(props));
} else {
    renderApp(props);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (site === 'admin') {
    serviceWorkerRegistration.unregister();
} else {
    serviceWorkerRegistration.register();
}
