import Base from './Base';

class InteractionsApi extends Base {
    constructor(opts = {}) {
        super({
            ...opts,
            routes: {
                // index: 'interactions',
                index: 'short-interactions',
                results: 'results',
                delete: 'delete-interactions',
                ...(opts.routes || null),
            },
        });
    }

    get() {
        return this.requestGet(this.route('index'));
    }

    results() {
        return this.requestGet(this.route('results'));
    }

    post(data = null) {
        return this.requestPost(this.route('index'), data);
    }

    delete(questionId = null) {
        return this.requestPost(this.route('delete'), { questionId });
    }
}

export default InteractionsApi;
